body {
	min-height:100vh;
	display:flex;
	#root {
		flex-grow: 1;
		display:flex;
		flex-direction: column;
	}
}

table td {
	vertical-align: top;
  } 

.insufficient-credits {
	background-color: #8d1100;
}
